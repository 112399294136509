:root{
  --colorM1: #e2eaff;
  --colorM1: #0084c3;
  --colorM1_off: #0e6791;
  --colorM2: #fff6e2;
  --colorM2: #ffd400;
  --colorM2_off: #c1b958;

  --colorM1: #fff;
  
  --logo_font: 'Oswald'
}

.FA-icon{
  position: relative;
}
.FA-icon svg{
  width: 100% !important;
  height: 100% !important;
}

p:not(.post-body p){
    font-family: sans-serif;
    font-weight: 200;
    color: #1c1c1c;
}
@media only screen and (max-width: 850px){
  #main{
    overflow-x: hidden;
  }
}