#social-container{
    display: flex;
    flex-direction: row;
    margin: 20px 15px 0 0;
    /* background: #ffffff91; */
    z-index: 1;
    border-radius: 10px;
}
.social-link-container{
    height: 30px;
    width: 30px;
    color: black;
    margin: 2px;
    padding: 10px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0 0 2px #666;
}
.social-link-container:hover{
    color: var(--colorM2);
}