/* .hex-top{
    width: 0;
    border-bottom: 30px solid #6C6;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
}
.hex {
    width: 104px;
    height: 60px;
    background-color: #6C6; 
}
.hex-bot{
    width: 0;
    border-top: 30px solid #6C6;
    border-left: 52px solid transparent;
    border-right: 52px solid transparent;
} */

/* .simple-hex{
    position: absolute;
}

.hex-top{
    transform: translateY(1px);
}

.hex-bot{
    transform: translateY(-1px);
} */

.hex-center{
    position: absolute;
    top: 0px;
    left: 0px;
    margin-left: auto; 
    margin-right: auto; 
    margin-top: auto;
    margin-bottom: auto;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    font-size: 2.5em;
    font-weight: bold;
}

.hex-lattice{
    display: flex;
    flex-direction: column;
}
.lattice-flip .hex-lattice{
    padding-top: 50px;
}
.lattice-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.lattice-item.odd{
    transform: translateY(62.5%);
}

.lattice-flip .lattice-item.odd{
    transform: translateY(-62.5%);
}

.lattice-item{
    
}