.slider-container{
    width: 100%;
    height: auto;
    overflow:hidden;
    position: relative;

}

.page-container{
    display: flex;
    height: 100%;
    flex-direction: row;
    width: 100%;
    position: relative;
    transition: .5s;
}

.page-window{
    margin: auto;
    overflow: hidden;
}
.slider-page{
    width: calc(100% - 10px);
    flex: 0 0 auto;
    padding: 0 5px 0 5px;
}

.slider-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
}
.slider-item{
}
.page-select{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
}
.page-selector-button{
    height: 15px;
    width: 15px;
    background-color: #666;
    opacity: .5;
    border-radius: 15px;
    margin-left: 5px;
    margin-right: 5px;
}
.page-selector-button.page-active{
    opacity: 1;
    background-color: black;
    /* border: 1px solid var(--colorM2); */
}
.page-selector-button:hover{
    opacity: 1;
    background-color: var(--colorM2);
}
.page-count{
    position: absolute;
    top: 5px;
    left: 45px;
}
.bumper{
    position: absolute;
    top: 0;
    height: 100%;
    background-color: transparent;
    display: flex;
}
.bumper-left{
    border-radius: 5px 0 0 5px;
}

.bumper-right{
    border-radius: 0 5px 5px 0;
}

.bumper-arrow{
    margin-top: auto;
    margin-bottom: auto;
}
.bumper:hover{
    /* background-color: #6666;
    background-color: rgba(255, 212, 0, .4); */
    cursor: pointer;
}
.bumper:hover .FA-icon{
    color: var(--colorM2);
}
.bumper-left{
    left: 0;
}

.bumper-right{
    right: 0;
}


.youtube-item{
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 1px black;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}

.youtube-thumb{
    width: 100%;
    height: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 75%;
    display: flex;
    align-content: center;
    justify-content: center;
    background-size: contain;
    background-position: center;
}
.youtube-item .youtube-icon{
    color: black;
}
.youtube-item:hover .youtube-icon{
    color: red;
}
.youtube-icon{
    width: 75px;
    height: 75px;
    margin: auto;
    padding-top:calc(.5 * (75% - 75px));
    opacity: .7;
}
.youtube-details{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 15px;
    background-color: white;
}
.youtube-title{
    font-size: 1.0em;
    line-height: 1.3em;
    height: 2.6em;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    font-weight: 600;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}