@font-face {
  font-family: "RampartOneRegular";
  src: local("RampartOneRegular"),
  url("./assets/RampartOne-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
  url("./assets/Oswald-VariableFont_wght.ttf") format('truetype');
  font-weight: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, sConsolas, 'Courier New',
    monospace;
}

h1{
  font-family: var(--logo_font);
  font-size: 3.5rem;
}

.header-font{
  font-family: var(--logo_font);
}