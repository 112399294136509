.charity-tree-wrapper{
    height: 100%;
}
#charity-tree{
    /* display: flex;
    flex-direction: column; */
    background-size: 84.7%;
    background-position: top 144px center;
    background-repeat: no-repeat;
    position: relative;
    height: 100%;
}
#charity-container-mobile{
    height: 100%;
}
.mobile-charity-tree-wrapper{
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
}
#mobile-charity-tree{
    height: 750px;
    background-size: 54%;
    background-position: top 150px center;
    background-repeat: no-repeat;
    position: relative;
    width: 452px;
}
.charity-svg-container{
    position: absolute;
    transition: .6s;
    transition-delay: 1s;

}
.charity-svg{
    background-repeat: no-repeat;
    background-size: cover;
    transition: .6s;
    transition-delay: 1s;
}
.charity-svg-container-grow{
    transition: .4s;
}
.charity-svg-container:not(.selected) .charity-svg-container-grow:hover{
    transform: scale(1.15);
    cursor: pointer;
}
.charity-svg-container.selected{
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, calc(-50% - 100px)) !important;
    background-color: white;
    transition-delay: 0s;
}
.charity-svg-container.selected .charity-svg{
    transition-delay: 0s;
}
.svg-drawer{
    height: 450px;
    max-height: 0px;
    overflow: hidden;
    transition: 1s;
    box-shadow: 0 0 2px #666;
    transition-delay: 0s;
    border-radius: 3px;
}
.drawer-text{
    margin: 3px;
    height: 444px;
    overflow-y: scroll;
    font-size: 1.1em;
    padding: 5px;
}
.close-svg{
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
}
.close-svg:hover{
    color: var(--colorM2);
}
.svg-drawer.open{
    max-height: 450px;
    transition-delay: .6s;
    z-index: 3;
}
.tree-row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 15px;
}
.tree-row-group{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
#tree-row-1{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tree-branch{
    height: 10px;
    border: 4px solid black;
    border-bottom: none;
}

.tree-hex{
    margin: 5px;
    position: relative;
}

.tree-hex svg{
    position: absolute;
    top: 0;
    left: 0;
}
.hex-pop{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: .4s;
    margin-top: 10px;
}

.hex-pop-inner{
    margin: auto auto auto auto;
    width: 80%;
    height: 80%;
    background-color: #e4d86da6;
    border-radius: 15px;
    padding: 15px;
    box-shadow: 0 0 2px #666;
}

#banner-title{
    margin: 0;
}
#amount-donated{
    position: absolute;
    width: 100%;
    margin: 0;
    text-align: center;
}
#edf-logo{
    transform: scale(1.4) translateX(3px);
}