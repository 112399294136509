#homepage-container{
    position: relative;
    transition: .3s;
}

#homepage-content{
    position: relative;
    background-color: var(--colorM2);
    background-color: transparent;
}
#tmp-yt-link:hover{
    opacity: .75;
}
/* #content-left-banner{
    position: fixed;
    height: calc(100vh - 150px);
    top: 150px;
    left: 0;
    background-color: var(--colorM1);
    box-shadow: 0px 6px 6px #666;
    z-index: 3;
} */
#content-left-banner{
    position: fixed;
    height: calc(100vh);
    top:0;
    left: 0;
    background-color: var(--colorM1);
    box-shadow: 0px 6px 6px #666;
    z-index: 3;
}
/* #content-left-banner{
    position: fixed;
    height: calc(100vh);
    top: 0px;
} */
#banner-title-container{
    height: 80px;
}
#banner-content-wrapper-mobile{
    height: 320px;
    display: flex;
    flex-direction: row;
}
#mobile-banner-hex-container{
    margin-top: auto;
    margin-bottom: auto;
}
.charity-list{
    margin: 5px;
    border: 1px solid var(--colorM2);
    border-radius: 5px;
    padding-left: 10px;
    box-shadow: inset 0 0 3px 0px var(--colorM2);
    overflow-y: scroll;
}
#charity-container{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.charity-container{
    color: white;
}
.charity-container:hover{
    color: var(--colorM2);
}
#content-main{
    height: 100%;
}
.video-container{
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    margin-bottom:40px;
}

/* .homepage-blurb-container{
    margin-left: 15px;
    position: relative;
    margin-right: 15px;
    margin-top: 40px;
    box-shadow: 0 0 1px #666;
    border-radius: 15px;
    padding: 25px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
} */

#center-hex-container{
    width: auto;
    margin: auto;
    height: 0;
    padding-bottom: 33%;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
}

.homepage-blurb-container{
    position: relative;
    padding: 25px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    min-height: calc(100vh - 300px);
    background:transparent;
}
.homepage-blurb-container h1{
    margin-left: 25px;
    margin-right: 25px;
    text-align: right;
}
.homepage-blurb-container.odd h1{
    text-align: left;
}
.blurb-col{
    display: flex;
    flex-direction: column;
    flex: 1 0;
    z-index: 2;
    max-width: 100%;
    /* background-color: rgba(255, 212, 0, .15); */
    border-radius: 15px;
    box-shadow: 0 0 4px var(--colorM2);
    box-shadow: 0 0 4px #666;
    
}
.blurb-col p{
    font-size: 1.2em;
    line-height: 1.8em;
    padding: 45px;
}

.blurb-background{
    position: absolute;
    top: 11%;
    left: 29px;
    opacity: .5;
    width: 550px;
    height: 250px;
    background-repeat: no-repeat;
}
.homepage-blurb-container.odd > .blurb-background{
    right: 29px;
    left: auto;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

#banner-title{
    color: white;
    color: #000;
    text-align: center;
}

.youtube-item{
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 1px black;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
}

.youtube-thumb{
    width: 100%;
    height: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 75%;
    display: flex;
    align-content: center;
    justify-content: center;
}
.youtube-item .youtube-icon{
    color: black;
}
.youtube-item:hover .youtube-icon{
    color: red;
}
.youtube-icon{
    width: 75px;
    height: 75px;
    margin: auto;
    padding-top:calc(.5 * (75% - 75px));
    opacity: .7;
}
.youtube-details{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 15px;
}
.youtube-title{
    font-size: 1.0em;
    line-height: 1.3em;
    height: 2.6em;
    padding-left: 10px;
    padding-right: 10px;
    color: black;
    font-weight: 600;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#footer{
    height: auto;
    background-color: var(--colorM1);
    color: #064563;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-bottom: 20px;
    min-height: 200px;
    justify-content: flex-end;
}
.footer-line-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#footer p{
    margin: 0;
    font-weight: bold;
}
.footer-line{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
}
.footer-line-content{
    margin: 0 5px 0 5px;
}
.contact-seg{
    display: flex;
    flex-direction: row;
}
.seg-content{
    line-height: 30px;
}
.seg-title{
    font-size: 20px;
    width: auto;
    font-family: 'Oswald';
    color: black;
    text-align: center;
}
.seg-body{
    display: flex;
    justify-content: space-evenly;
}
.footer-line-title{
    color: black;
    font-family: 'Oswald';
    font-size: 30px;
}
#footer h3{
    margin: 0;
}
@media only screen and (max-width: 850px){
    .charity-list{
        flex: 1 0 auto;

    }
    #content-left-banner{
        position: relative;
        width: 255px;
        top: 0;
        height: 855px;
    }
    #homepage-container.menu-open{
        transform: translateX(250px);
    }
    .blurb-background{
        width: 88%;
        top:100px;
    }
    .homepage-blurb-container{
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
    .blurb-col p{
        padding: 15px;
    }
}