.hash-link{
    display: contents;
    color: black;
}
#nav-container{
    height: 150px;
    background-color:var(--colorM1);
    position: relative;
    /* position: -webkit-sticky;
    position: sticky; */
    overflow: hidden;
    top:0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    box-shadow: 0 1px 6px 0px #666;
    z-index: 10;
}
#nav-right-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
#nav-logo-container{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
}

#nav-logo-main{
    height: 100%;
    width: 250px;
    fill: black;
    stroke: black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
#nav-logo-main svg{
    height: 100%;
    width: 100%;
}

#nav-logo-text{
    font-size: 65px;
    color: white;
    color: black;
    font-weight: 900;
    margin-left: 0px;
    font-family: var(--logo_font);
    letter-spacing: 9px;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    line-height: 1;
    align-items: center;
    z-index: 1;
}
#breaking-logo-on-text{
    background-repeat: no-repeat;
    flex: 1 1;
    width: 100%;
    background-position: 50%;
}
#nav-logo-text .big-letter{
    font-size: 100px;
    font-size:45px;
}

.nav-links-container{
    display: flex;
    flex-direction: row;
    font-size: 1.5rem;
    align-items: flex-end;
    z-index: 4;
    color: black;
}
.tab-content{
    text-decoration: none;
    color: #000;
}
.nav-links-sticky{
    position: sticky;
    top: 0;
    right: 0;
    z-index: 100;
    justify-content: flex-end;
    width: fit-content;
    margin-right: 0;
    margin-left: auto;
    background-color: var(--colorM1);
    border-radius: 0 0 0px 5px;
    padding-right: 10px;
    padding-left: 10px;
    height: 45px;
    box-shadow: -0px 0px 1px #666;
}
#breaking-logo{
    position: absolute;
    width: 100%;
    height: 133px;
    right: 430px;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: bottom;
    background-position-x: right;
    
}

.tab-container{
    margin: 5px;
    margin-bottom: 10px;
    font-family: var(--logo_font);
}
.tab-container a{
    text-decoration: none;
}
.tab-container :hover{
    color: var(--colorM2);
}

.mobile-tab-menu .tab-container :hover{
    color:var(--colorM2)
}
#nav-hamburger-button{
    display: none;
}
#mobile-tab-menu{
    display: none;
}
#mobile-nav-links{
    display: none;
}
#mobile-nav-container-background{
    display: none;
}
@media only screen and (max-width: 1100px){
    #nav-logo-text{
        font-size: 51px;
        margin-left: 0px;
        line-height: 50px;
        letter-spacing: 0px;
        transform: translateX(-25px);
    }
    #nav-logo-text .big-letter{
        font-size: 80px;
        font-size: 45px;
    }
    .nav-links-container{
        font-size: 1.2rem;
    }
    #breaking-logo{
        height: 116px;
        right: 312px;
        width: 40%;
    }
}

@media only screen and (max-width: 850px){
    #nav-container{
        height: auto;
        padding-top: 20px;
        padding-bottom: 15px;
        position: relative;
        transition: .3s;
    }
    #nav-logo-container{
        flex-direction: column;
        flex-direction: column-reverse;
        width: 100%;
        align-items: center;
        z-index: 2;
    }
    #nav-logo-text{
        transform: none;
    }
    #breaking-logo{
        display: none;
    }
    #nav-logo-text .big-letter{
        font-size: 85px;
        font-size: 40px
    }
    #nav-logo-main{
        height: 250px;
    }
    .nav-links-container{
        display: none;
    }
    #nav-hamburger-button{
        display: inline;
        position: fixed;
        z-index: 100;
        top: 10px;
        left: 10px;
    }
    #hamburger-icon{
        width: 2.5em;
        height: 2.5em;
        position: absolute;
        color: var(--colorM2);
        stroke: black;
        stroke-width: 78px;
        paint-order: stroke;
    }

    .mobile-tab-menu{
        position: fixed;
        z-index: 10;
        top: 0;
        left:0;
        height: 100vh;
        width:0px;
        overflow: hidden;
        transition: .3s;
        color: black;
    }
    .mobile-tab-menu a{
        text-decoration: none;
        color: black;
    }
    .mobile-tab-menu.menu-open{
        width: 250px;
        box-shadow: inset 0 0 4px 0px black;    
    }
    .mobile-nav-links-wrapper{
        width: 90%;
        height: calc(100% - 120px);
        margin: auto;
        margin-top: 60px;
        border-radius: 5px;
        border: 2px solid var(--colorM1);
        box-shadow: inset 0 0 3px 0 black
    }
    #nav-container.menu-open{
        transform: translateX(250px);
    }
    #mobile-nav-links{
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        color: black;
        font-size: 2.5em;
        padding: 5px;
    }
    #nav-right-container{
        width: 100%;
        align-items: center;
    }
    #nav-container-background{
        display: none;
    }
    #mobile-nav-container-background{
        display: block;
    }

}

@media only screen and (max-width: 400px){
    #nav-logo-text{
        letter-spacing: 7px;
    } 
}
