.blog-container{
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px;
    box-shadow: 0px 2px 2px #666;
    min-height: calc(100vh - 180px);
    min-width: 500px;
}

.post-body{
    color:#757575;
    font:15px Roboto, sans-serif;
    line-height:1.6em;
    margin:1.5em 0 2em 0;
    display:block
}

.post-title{
    font: bold 22px Roboto, sans-serif;
    float: left;
    margin: 0 0 8px 0;
    max-width: calc(100% - 48px);
    color: #212121;
}